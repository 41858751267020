import { getSdk, SdkFunctionWrapper } from './generated/sdk'
import { GraphQLClient } from 'graphql-request'
import store from './store/index'

export type Sdk = ReturnType<typeof getSdk>

export function sdk(): Sdk {
  const client = new GraphQLClient('https://backend.prod.goose-b2b.madx.nl/graphql')
  client.setHeader('Authorization', 'Bearer ' + store.getters.token)
  const wrapper: SdkFunctionWrapper = async (action) => {
    let result
    try {
      result = await action()
    } catch (e: any) {
      result = e.response.data
      for (const error of e.response.errors) {
        store.commit('errorMessage', error)
      }
    }
    return result
  }
  return getSdk(client, wrapper)
}
